import NextLink from 'next/link'
import { Box, Image, Text, Flex, useToken, Link } from '@chakra-ui/react'
import {
  getProductAttribute,
  getProductAttributes,
  getAttributeFromMasterVariant,
} from 'composable/helpers/utils/product-card-utils'
import { parseAttributeArrayIntoString } from 'composable/helpers/utils/string-utils'
import { COUPON_CODE } from 'helpers/constants/localStorage'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoImagesOutline } from 'react-icons/io5'
import { useLocale } from 'frontastic/contexts'
import { HorizontalProductCardProps } from './types'
import { readOnlyCartOptions as cardOptions, formatPrice, cartlabels, parseFinalURL } from './utils'
import { CurrencyHelpersWithoutBorderConversion } from '../../../helpers_composable/currencyHelpersWithoutBorderConversion'
import { SHIPPING_METHODS } from '../general/constants'
import { LoyaltyPoints } from '../loyalty-points'
export const HorizontalProductCardReadOnly = (props: HorizontalProductCardProps) => {
  const {
    columns = 3,
    size = 'lg',
    image,
    name,
    price,
    discountedPrice,
    quantity,
    variant,
    url,
    masterProductData,
    custom,
    basePrice,
    isDiscounted,
    totalPrice,
    promoDiscounts,
    shippingMethod,
    selectedShippingMethod,
    priceAllDetails,
    appliedDiscountCode,
    discounts,
  } = props

  const { formatMessage } = useFormat({ name: 'common' })
  const labels = cartlabels(formatMessage)
  const { currency } = useLocale()
  const [brandSecondary] = useToken('colors', ['brand.secondary'])
  const { src: imageSrc, alt: imageAlt = name } = image ?? {}

  const segment2Label = masterProductData?.find(({ name }) => name === 'segment2Label')?.value
  const segment3Label = masterProductData?.find(({ name }) => name === 'segment3Label')?.value
  const productAttributes = getProductAttributes(variant, segment2Label, segment3Label) ?? []
  const parsedProductAttributes = parseAttributeArrayIntoString(productAttributes) ?? ''
  const productItemNumber = getProductAttribute({ variant, attribute: 'itemNumber' })
  const brand = getAttributeFromMasterVariant({ masterProductData, attribute: 'brand' })

  const quantityLabel = labels.quantity ?? ''
  const itemPriceLabel = labels.itemPrice ?? ''

  const imageSize = cardOptions[columns][size].imageSize
  const quantityOptions = cardOptions[columns][size].quantity
  const itemPriceOptions = cardOptions[columns][size].itemPrice
  const contentColumn = cardOptions[columns][size]?.contentColumn

  const formattedPrice = formatPrice(price)
  const isPromoExcluded = custom?.fields?.isPromoExcluded
  const hasEdsCode = Boolean(priceAllDetails?.custom?.fields?.edsCode?.trim())
  const _url = parseFinalURL(name, url)
  const GIFT_CARD_NAME = 'Gift Card'

  const CustomCharges = ({ quantity }) => {
    if (!custom || (!custom?.fields?.customizationDataCuff && !custom?.fields?.customizationDataInseam)) return <></>

    const chargeValue = CurrencyHelpers.formatForCurrency(quantity * custom?.fields?.customizationCharge?.centAmount)
    const customizationDataCuff = custom?.fields?.customizationDataCuff
    const customizationDataInseam = custom?.fields?.customizationDataInseam

    return (
      <Flex
        direction="row"
        alignItems="center"
        gap={1}
        tabIndex={0}
        aria-label={`Inseam=${customizationDataInseam}, Cuffs=${customizationDataCuff} : ${chargeValue}`}
      >
        <Text>{`Inseam=${customizationDataInseam}`}</Text>
        <Text>{`, Cuffs=${customizationDataCuff}`}</Text>
        <Text>{`: ${chargeValue}`}</Text>
      </Flex>
    )
  }

  const getEstimateDeliveryDate = () => {
    let formattedDate = ''
    if (props?.estimateDeliveryDate) {
      const dateObj = new Date(props?.estimateDeliveryDate)
      formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })?.format(dateObj)
    }
    return formattedDate
  }

  const shippingMessage = () => {
    if (shippingMethod === SHIPPING_METHODS.SHIPPING) {
      const dateString = getEstimateDeliveryDate()
      if (dateString) {
        return formatMessage({
          id: 'checkout.shippingMethod.shipping',
          values: {
            shippingDetail: dateString,
          },
        })
      }
    } else {
      return formatMessage({
        id: 'checkout.shippingMethod.pickup',
        values: {
          shippingDetail: custom?.fields?.pickUpStoreName,
        },
      })
    }

    return ''
  }

  const ShowIfPromoCodeAppliedToLineItem = ({ discounts, appliedDiscountCode }) => {
    const appliedCartDiscountID = appliedDiscountCode?.flatMap((discount) =>
      discount.cartDiscounts.map((cartDiscount) => cartDiscount.cartDiscountId),
    )?.[0]
    const isPromoCodeAppliedToLineItem = discounts?.some((discount) => discount.discountId === appliedCartDiscountID)

    if (isPromoCodeAppliedToLineItem) {
      const promoCode = localStorage.getItem(COUPON_CODE) || appliedDiscountCode?.[0]?.code
      const messageToShow = promoCode ? `${promoCode} Applied` : ''

      return (
        <Text
          tabIndex={0}
          fontSize={'12px'}
          textStyle={'body-50'}
          color={'danger.600'}
          fontWeight={400}
          whiteSpace={'nowrap'}
        >
          {messageToShow}
        </Text>
      )
    } else {
      return null
    }
  }

  return (
    <Box padding={4}>
      <Flex gap={4}>
        <Flex
          aspectRatio={3 / 4}
          width={imageSize.width}
          height={imageSize.height}
          onClick={image?.onClickImage}
          cursor={image?.onClickImage ? 'pointer' : undefined}
        >
          <Link as={NextLink} href={_url} aria-label={name}>
            {imageSrc ? (
              <Image src={imageSrc} alt={imageAlt} fit="contain" width={'full'} height={'full'} />
            ) : (
              <Flex justify={'center'} align={'center'} width={'full'} height={'full'} bgColor={'brand.muted'}>
                <IoImagesOutline size={'40px'} color={brandSecondary} />
              </Flex>
            )}
          </Link>
        </Flex>

        <Flex {...contentColumn} grow={1}>
          <Flex direction="column" flex={1}>
            <Text textStyle="body-50" color="primary">
              {brand}
            </Text>
            <Text
              as={NextLink}
              href={_url}
              textStyle={'body-75'}
              fontWeight={600}
              _hover={{ textDecoration: 'none', color: 'text-primary' }}
            >
              {name}
            </Text>

            {quantityOptions.type === 'text' && (
              <Text textStyle={'body-50'} color={'text-muted'}>
                {quantityOptions.label && `${quantityLabel}: `} {quantity}
              </Text>
            )}

            <Flex textStyle={'body-50'} color={'text-muted'} my={1} flexDirection="column">
              <Text>
                {productItemNumber ? `Item #: ${productItemNumber}, ` : ``} {parsedProductAttributes}
              </Text>

              {name != GIFT_CARD_NAME && name != 'ST JUDE DONATION' ? (
                <LoyaltyPoints
                  pricedata={isDiscounted ? formatPrice(discountedPrice) : formatPrice(price)}
                  textAlign={'left'}
                ></LoyaltyPoints>
              ) : (
                ''
              )}

              <CustomCharges quantity={quantity} />
            </Flex>

            {itemPriceOptions.type === 'text' && (
              <Flex flexDirection="column">
                <Flex gap={1}>
                  <Text textStyle={isDiscounted ? 'figma-strikethrough-75' : 'body-75'}>
                    {isDiscounted ? basePrice : price}
                  </Text>

                  {isDiscounted && (
                    <Text textStyle={'body-75'} color="danger.600">
                      {isDiscounted ? discountedPrice : totalPrice}
                    </Text>
                  )}
                </Flex>

                {promoDiscounts?.discountAmount > 0 && itemPriceOptions.type !== 'column' && (
                  <>
                    <Text tabIndex={0} fontSize={'12px'} textStyle={'body-50'} color={'danger.600'} fontWeight={400}>
                      {formatMessage({
                        id: 'product.price.sale.youSaved',
                        values: {
                          amount: CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                            promoDiscounts.discountAmount,
                            currency || 'USD',
                          ),
                        },
                      })}
                    </Text>
                  </>
                )}

                <ShowIfPromoCodeAppliedToLineItem discounts={discounts} appliedDiscountCode={appliedDiscountCode} />

                {isPromoExcluded && (!hasEdsCode || (hasEdsCode && quantity > 1)) && (
                  <Box
                    role="textbox"
                    aria-label={formatMessage({ id: 'checkout.promotionexclution.message' })}
                    tabIndex={0}
                    textStyle={'body-50'}
                    color={'text-muted'}
                    dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'checkout.promotionexclution.message' }) }}
                    marginBottom={5}
                  />
                )}

                {name != GIFT_CARD_NAME && (
                  <Text textStyle="body-50" color="text-muted" pt={1}>
                    {shippingMessage()}
                  </Text>
                )}
              </Flex>
            )}
          </Flex>

          {quantityOptions.type === 'column' && (
            <Box textAlign={'center'} mr={6}>
              {quantityOptions.label && (
                <Text color={'text-muted'} textStyle={'heading-mobile-75'}>
                  {quantityLabel}
                </Text>
              )}
              <Text textStyle={'body-75'}>{quantity}</Text>
            </Box>
          )}

          {itemPriceOptions.type === 'column' && (
            <Box textAlign={'end'} mr={6}>
              {itemPriceOptions.label && (
                <Text mb={1} color={'text-muted'} textStyle={'heading-mobile-75'}>
                  {itemPriceLabel}
                </Text>
              )}

              <Box>
                {isDiscounted && (
                  <Text textStyle={'body-75'} color="danger.600">
                    {isDiscounted ? totalPrice : price}
                  </Text>
                )}

                <Text textStyle={isDiscounted ? 'figma-strikethrough-75' : 'body-75'}>
                  {isDiscounted ? discountedPrice : totalPrice}
                </Text>
              </Box>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Box,
  Text,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  Popover,
  List,
  ListItem,
  Image,
} from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { useAccountCT } from 'frontastic/contexts'
import { useRewards } from '../components/rewards-club/hooks/useRewards'

export const LoyaltyPoints = ({ pricedata, textAlign }) => {
  const { loggedIn } = useAccountCT()
  const { summary } = useRewards()
  const [points, setPoints] = useState<number | null>(null)

  useEffect(() => {
    userLogin()
  }, [summary, pricedata])

  const userLogin = async () => {
    const tier = await summary
    if (loggedIn && tier) {
      if (tier?.current_tier?.tier_name === 'classic') {
        let roudup = Math.round((pricedata / 100) * 10)
        setPoints(roudup)
      } else if (tier?.current_tier?.tier_name === 'premium') {
        let roudup = Math.round((pricedata / 100) * 11)
        setPoints(roudup)
      } else if (tier?.current_tier?.tier_name === 'elite') {
        let roudup = Math.round((pricedata / 100) * 12)
        setPoints(roudup)
      }
    } else {
      let roudup = Math.round((pricedata / 100) * 10)
      setPoints(roudup)
    }
  }
  const { formatMessage } = useFormat({ name: 'common' })
  return (
    <Box display={'flex'} justifyContent={textAlign}>
      <Box textAlign="left" mt={2}>
        <Popover>
          <PopoverTrigger>
            <Text
              textStyle="ui-button-75"
              cursor={'pointer'}
              display={'flex'}
              gap={1}
              height={'25px'}
              borderRadius={'var(--radii-base,4px)'}
              py={1}
              px={2}
              backgroundColor={'#D7E2EC'}
              color={'#2F3F4F'}
            >
              + {points} Points <IoIosInformationCircleOutline fontSize={'1rem'} />
            </Text>
          </PopoverTrigger>
          <PopoverContent width={'166px'} borderRadius={'var(--radiibase),4px'}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody p={3} gap={2} display={'flex'} flexDirection={'column'}>
              <Box>
                <Text textStyle={'heading-desktop-50'} color="var(--semantic-text-primary, #15191C)">
                  {formatMessage({ id: 'rewards.heading' })}
                </Text>
                <Text textStyle={'body-50'} color="var(--semantic-text-primary, #15191C)">
                  {formatMessage({ id: 'rewards.subheading' })}{' '}
                </Text>
              </Box>

              <List display={'flex'} flexDirection={'column'} gap={1} spacing={3}>
                <ListItem textStyle={'body-50'} color="var(--semantic-text-primary, #15191C)" display={'flex'}>
                  <Image src="/icons/loyalty-icons/rewards-icon_bronze.svg" />
                  {formatMessage({ id: 'rewards.points.classic' })}
                </ListItem>
                <ListItem textStyle={'body-50'} color="var(--semantic-text-primary, #15191C)" display={'flex'}>
                  <Image src="/icons/loyalty-icons/rewards-icon_silver.svg" />
                  {formatMessage({ id: 'rewards.points.premium' })}
                </ListItem>
                <ListItem textStyle={'body-50'} color="var(--semantic-text-primary, #15191C)" display={'flex'}>
                  <Image src="/icons/loyalty-icons/rewards-icon_gold.svg" />
                  {formatMessage({ id: 'rewards.points.elite' })}
                </ListItem>
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </Box>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import {
  Box,
  Image,
  Text,
  CloseButton,
  Flex,
  Stack,
  useToken,
  Link,
  useBreakpointValue,
  SkeletonText,
} from '@chakra-ui/react'
import { SHIPPING_METHODS } from 'composable/components/general/constants'
import { getCartProductQtyByVariantSku } from 'composable/helpers/utils/cart-utils'
import { COUPON_CODE } from 'helpers/constants/localStorage'
import { CurrencyHelpers } from 'helpers/currencyHelpers'
import { useFormat } from 'helpers/hooks/useFormat'
import { utagAddToCart, utagRemoveToCart } from 'helpers/tealiumHelper'
import { CurrencyHelpersWithoutBorderConversion } from 'helpers_composable/currencyHelpersWithoutBorderConversion'
import { IoImagesOutline } from 'react-icons/io5'
import { useCartCT } from 'frontastic'
import { getInventoryOfProductBySku } from 'frontastic/actions/product'
import { useLocale, useAccountCT } from 'frontastic/contexts'
import { DropShipProductMessage } from 'frontastic/tastics/composable/product-detail-page/components/DropShipProductMessage'
import { BackOrderMessage } from './back-order-message'
import { LowInStockMessage } from './low-in-stock-message'
import { NotEnoughStockAlertMessage } from './not-enough-stock-alert-message'
import { OutOfStockAlertMessage } from './out-of-stock-alert-message'
import { HorizontalProductCardProps } from './types'
import {
  cartlabels,
  cartOptionValues,
  evaluateBackorder,
  fetchAttributeData,
  formatPrice,
  parseFinalURL,
} from './utils'
import AtgForgotPassword from '../account/atg-forgot-password'
import AtgLoginPage from '../account/atg-login-page'
import { OptionsGetProduct } from '../general/components/options-get-product'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { LoyaltyPoints } from '../loyalty-points'
import { storeDataDefaultValue } from '../mystore/constants'
import { getStoreTimingMessage } from '../mystore/utils'
import { QuantityPicker } from '../quantity-picker'
import { DONATIONS } from 'composable/helpers'

enum TealiumTrackingType {
  CART_ADD = 'cart_add',
  CART_REMOVE = 'cart_remove',
}

export const HorizontalProductCardEditable = (props: HorizontalProductCardProps) => {
  const {
    lineItemId,
    columns = 3,
    size = 'lg',
    image,
    name,
    quantity,
    variant,
    onRemove,
    onChangeQuantity,
    url,
    masterProductData,
    optionsGetProduct,
    showGetProductOptions = false,
    editable,
    isDonationOrGiftCard,
    availabilityStatus,
    custom,
    isPromoExcluded,
    isDiscounted,
    price,
    basePrice,
    discountedPrice,
    promoDiscounts,
    totalPrice,
    priceAllDetails,
    priceDetails,
    usedInBagDrawer = false,
    appliedDiscountCode,
    discounts,
  } = props

  const router = useRouter()
  const isCartPage = router.asPath.includes('/cart')
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const { formatMessage } = useFormat({ name: 'common' })

  const hasEdsCode = Boolean(priceAllDetails?.custom?.fields?.edsCode?.trim())
  const _url = parseFinalURL(name, url)

  const isBOPISProduct = custom?.fields?.shippingMethod === SHIPPING_METHODS.PICKUP
  const [algoliaProductAddedToCart, setAlgoliaProductAddedToCart] = useLocalStorage('algoliaProductAddedToCart', [])
  const isDropShipProduct = variant?.attributes?.isDropShip

  const [brandSecondary] = useToken('colors', ['brand.secondary'])
  const { account, loggedIn } = useAccountCT()
  const { currency } = useLocale()
  const { cart } = useCartCT()
  const [selectedStoreData] = useLocalStorage('selectedStoreData', storeDataDefaultValue)

  const [availableQuantity, setAvailableQuantity] = useState<number | null>(null)
  const [backorderLevel, setBackOrderLevel] = useState<number | null>(null)
  const [availableForOrder, setAvailableForOrder] = useState<number | null>(null)
  const [ctAvailableQuantity, setCtAvailableQuantity] = useState<number | null>(null)
  const [isLoginModelOpen, setLoginModelOpen] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [QPQuantity, setQPQuantity] = useState(quantity)
  const [prevQuantity, setPrevQuantity] = useState(quantity)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAvailable, setIsAvailable] = useState(false)

  const getAvailability = async () => {
    try {
      const pickUpStoreId = custom?.fields?.pickUpStoreId
      const parsedStoreId = pickUpStoreId?.match(/\d+/)?.[0]

      if (!parsedStoreId) {
        throw new Error(`Invalid pickUpStoreId : ${pickUpStoreId}`)
      }

      const pickupStoreAddress = cart.itemShippingAddresses.find((address) => address.key === parsedStoreId)

      const isAvailableTodayForPickUp = getStoreTimingMessage(
        JSON.parse(pickupStoreAddress?.additionalAddressInfo ?? '[]'),
      )
      setIsAvailable(isAvailableTodayForPickUp?.isAvailableToday ?? false)
    } catch (error) {
      console.error('Error fetching store:', error)
      setIsAvailable(false)
    }
  }

  // Use useRef for caching data to prevent re-fetching the same SKU
  const dataMap = useRef([])

  const variantCountInCartForShippingOrBOPIS = useMemo(
    () =>
      getCartProductQtyByVariantSku({
        variantSku: variant?.sku,
        cart,
        isBOPISProduct,
        userSelectedStoreNumber: selectedStoreData?.custom?.fields?.lastname,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variant?.sku, cart?.lineItems],
  )

  // Fetch available quantity asynchronously
  const fetchAvailableQuantity = useCallback(async () => {
    // Check if this SKU has already been fetched

    if (!isBOPISProduct) {
      // shipping items
      const res = await getInventoryOfProductBySku(variant.sku)
      dataMap.current.push(variant.sku) // Store the fetched result in dataMap
      const ctAvailableQuantity = res.availableQuantity ?? 0
      const ctBackOrderLevel = res.backorderlevel ?? 0
      setCtAvailableQuantity(ctAvailableQuantity)
      setBackOrderLevel(ctBackOrderLevel)

      if (variantCountInCartForShippingOrBOPIS) {
        setAvailableQuantity(ctAvailableQuantity - variantCountInCartForShippingOrBOPIS)
      } else {
        setAvailableQuantity(ctAvailableQuantity)
      }

      setAvailableForOrder(ctAvailableQuantity + ctBackOrderLevel)
    } else {
      // BOPIS items
      const BOPISQty = custom?.fields?.BOPISQtyForGivenStore?.availableQuantity ?? 0
      setCtAvailableQuantity(BOPISQty)
      setAvailableQuantity(BOPISQty - variantCountInCartForShippingOrBOPIS)
      setBackOrderLevel(0)
      setAvailableForOrder(BOPISQty + 0)
    }
  }, [
    isBOPISProduct,
    variant.sku,
    variantCountInCartForShippingOrBOPIS,
    custom?.fields?.BOPISQtyForGivenStore?.availableQuantity,
  ])

  const isDonation = DONATIONS.includes(String(variant?.key))

  const { isBackordered } = evaluateBackorder(availabilityStatus)

  let adjustedIsBackordered = isBackordered

  if (!isBackordered && backorderLevel > 1) {
    adjustedIsBackordered = true
  }

  const isInStock = ctAvailableQuantity >= variantCountInCartForShippingOrBOPIS

  const isLowInStock = availableQuantity <= 5

  const isNotEnoughInStock =
    !!availableQuantity && availableQuantity > 0 && availableQuantity < quantity ? availableQuantity : false

  // Fetching dynamic styles and options based on props
  const { imageSize, quantityOptions, itemPriceOptions, detailsOptions, removeOptions, contentColumn } =
    cartOptionValues({ columns, size })

  // Fetching specific attributes and labels based on props
  const { imageSrc, imageAlt, parsedProductAttributes, productItemNumber, brand, showPromoExclusion } =
    fetchAttributeData({
      image,
      masterProductData,
      variant,
      removedSegment3LabelWording: props?.removedSegment3LabelWording,
    })

  // Fetching localized labels for UI elements
  const labels = cartlabels(formatMessage)
  const removeButtonLabel = `${labels.remove} ${name}`

  const handleQuantityChange = (newQuantity) => {
    setQPQuantity(newQuantity)
  }

  const alertMessage = useMemo(() => {
    if (isBOPISProduct) {
      const BOPISQtyForGivenStore = custom?.fields?.BOPISQtyForGivenStore
      const availableBopisQty = BOPISQtyForGivenStore?.availableQuantity
      if (BOPISQtyForGivenStore?.inventoryMessage === 'OUT_OF_STOCK') {
        return <OutOfStockAlertMessage {...props} />
      }

      if (QPQuantity > availableBopisQty && availableBopisQty > 0) {
        return (
          <NotEnoughStockAlertMessage
            availableForOrder={availableBopisQty}
            quantity={QPQuantity}
            setQpQuantity={setQPQuantity}
            onChangeQuantity={handleQuantityChange}
            {...props}
          />
        )
      }

      if (availableBopisQty <= 5) {
        return <LowInStockMessage />
      }
    } else {
      if ((isLowInStock && ctAvailableQuantity >= QPQuantity) || availableForOrder === 0) {
        return <LowInStockMessage />
      }

      if (!(QPQuantity > availableForOrder) && adjustedIsBackordered && QPQuantity > ctAvailableQuantity) {
        return <BackOrderMessage />
      }

      if (QPQuantity > availableForOrder && availableForOrder > 0) {
        return (
          <NotEnoughStockAlertMessage
            availableForOrder={availableForOrder}
            quantity={QPQuantity}
            setQpQuantity={setQPQuantity}
            onChangeQuantity={handleQuantityChange}
            {...props}
          />
        )
      }

      if (
        (!!availableQuantity && !isInStock && !adjustedIsBackordered && !isDonationOrGiftCard) ||
        availableForOrder < 0
      ) {
        return <OutOfStockAlertMessage {...props} />
      }
    }
  }, [
    QPQuantity,
    adjustedIsBackordered,
    availableForOrder,
    availableQuantity,
    ctAvailableQuantity,
    isDonationOrGiftCard,
    isInStock,
    isLowInStock,
    props,
    custom?.fields?.BOPISQtyForGivenStore?.availableQuantity,
    custom?.fields?.BOPISQtyForGivenStore?.inventoryMessage,
  ])

  const handleUtagTracking = (qty: number, actionType: TealiumTrackingType) => {
    if (!priceDetails) return
    let algoliaProduct = algoliaProductAddedToCart.filter((product) => product?.skuId == variant.sku).pop()

    let product: any = {
      name,
      attributes: {
        brand: brand,
        name: name,
      },
    }

    let currentVariant: any = {
      discountedPrice: priceDetails?.discountedPrice || '',
      price: priceDetails?.price || {},
      key: variant?.key || '',
    }

    let algolia_query_id = algoliaProduct?.algolia_query_id || ''
    if (actionType === TealiumTrackingType.CART_ADD) {
      utagAddToCart({
        product,
        currentVariant,
        variant,
        breadcrumbs: algoliaProduct?.breadcrumbs || [],
        qty,
        isLoggedIn: loggedIn,
        userDetails: account,
        algoliaQueryId: algolia_query_id.split(' ')[1] || '',
        cartData: cart,
      })
    } else if (actionType === TealiumTrackingType.CART_REMOVE) {
      let itemPrice = priceDetails?.discountedPrice
        ? priceDetails?.discountedPrice.centAmount
        : priceDetails?.price?.centAmount
      itemPrice = itemPrice || 0
      let grandTotal = 0

      if (cart && 'sum' in cart) {
        grandTotal = cart.sum?.centAmount || 0
        grandTotal = grandTotal - itemPrice * qty
        cart.sum.centAmount = grandTotal > 0 ? grandTotal : 0
      } else {
        return
      }
      utagRemoveToCart({
        product,
        currentVariant,
        variant,
        breadcrumbs: algoliaProduct?.breadcrumbs || [],
        qty,
        isLoggedIn: loggedIn,
        userDetails: account,
        algoliaQueryId: algolia_query_id.split(' ')[1] || '',
        cartData: cart,
      })
      let filteredAlgoliaProduct = algoliaProductAddedToCart.filter((product) => product?.skuId != variant.sku)
      setAlgoliaProductAddedToCart(filteredAlgoliaProduct)
    }
  }

  const handleOnChangeQuantity = async (qty) => {
    setIsLoading(true)
    await onChangeQuantity(qty)
    setQPQuantity(qty)
    setIsLoading(false)
  }

  // Configured QuantityPicker component for consistent usage
  const ConfiguredQuantityPicker = () => (
    <QuantityPicker
      min={1}
      max={availableForOrder}
      hideLabel
      rootProps={{ minWidth: '110px', maxW: 'fit-content', minHeight: 8, height: '40px' }}
      isLoading={isLoading}
      value={QPQuantity}
      onChange={handleOnChangeQuantity}
      quantityProps={{ minWidth: size === 'sm' ? 6 : 9 }}
      buttonProps={{ width: '16px', height: '16px', minWidth: '16px' }}
    />
  )

  const callBackAfterLogin = () => {
    if (isLoginModelOpen) {
      setLoginModelOpen(false)
      // handleMoveToWishlist()
    }
  }

  const closeLoginModal = (obj) => {
    if (obj?.close == 'byClick') {
      setLoginModelOpen(false)
    }
  }

  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (isLoginModelOpen) {
      closeLoginModal({})
    }
  }

  const CustomCharges = ({ quantity }) => {
    if (!custom || (!custom?.fields?.customizationDataCuff && !custom?.fields?.customizationDataInseam)) return <></>

    const chargeValue = CurrencyHelpers.formatForCurrency(quantity * custom?.fields?.customizationCharge?.centAmount)
    const customizationDataCuff = custom?.fields?.customizationDataCuff
    const customizationDataInseam = custom?.fields?.customizationDataInseam

    return (
      <Flex
        direction="row"
        alignItems="center"
        gap={1}
        tabIndex={0}
        aria-label={`Inseam=${customizationDataInseam}, Cuffs=${customizationDataCuff} : ${chargeValue}`}
      >
        <Text>{`Inseam=${customizationDataInseam},`}</Text>
        <Text>{`Cuffs=${customizationDataCuff}:`}</Text>
        <Text>{chargeValue}</Text>
      </Flex>
    )
  }

  const ShowIfPromoCodeAppliedToLineItem = ({ discounts, appliedDiscountCode }) => {
    const appliedCartDiscountID = appliedDiscountCode?.flatMap((discount) =>
      discount.cartDiscounts.map((cartDiscount) => cartDiscount.cartDiscountId),
    )?.[0]
    const isPromoCodeAppliedToLineItem = discounts?.some((discount) => discount.discountId === appliedCartDiscountID)

    if (isPromoCodeAppliedToLineItem) {
      const promoCode = localStorage.getItem(COUPON_CODE) || appliedDiscountCode?.[0]?.code
      const messageToShow = promoCode ? `${promoCode} Applied` : ''

      return (
        <Text
          tabIndex={0}
          fontSize={'12px'}
          textStyle={'body-50'}
          color={'danger.600'}
          fontWeight={400}
          whiteSpace={'nowrap'}
        >
          {messageToShow}
        </Text>
      )
    } else {
      return null
    }
  }

  const RenderPriceComponent = () => (
    <Flex flexDirection="column" mt="10px">
      <Flex gap={1}>
        <Text textStyle={isDiscounted ? 'figma-strikethrough-75' : 'body-75'}>{isDiscounted ? basePrice : price}</Text>
        {isDiscounted && (
          <Text textStyle={'body-75'} color="danger.600">
            {isDiscounted ? discountedPrice : totalPrice}
          </Text>
        )}
      </Flex>

      {promoDiscounts?.discountAmount > 0 && itemPriceOptions.type !== 'column' && (
        <>
          <Text tabIndex={0} fontSize={'12px'} textStyle={'body-50'} color={'danger.600'} fontWeight={400}>
            {formatMessage({
              id: 'product.price.sale.youSaved',
              values: {
                amount: CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                  promoDiscounts.discountAmount,
                  currency || 'USD',
                ),
              },
            })}
          </Text>
        </>
      )}

      <ShowIfPromoCodeAppliedToLineItem discounts={discounts} appliedDiscountCode={appliedDiscountCode} />
    </Flex>
  )

  useEffect(() => {
    if (availableQuantity === null && ctAvailableQuantity === null && !dataMap.current.includes(variant.sku)) {
      fetchAvailableQuantity()
    }
  }, [fetchAvailableQuantity, availableQuantity, ctAvailableQuantity])

  useEffect(() => {
    setPrevQuantity(QPQuantity)
    if (QPQuantity > prevQuantity) {
      handleUtagTracking(QPQuantity, TealiumTrackingType.CART_ADD)
    }
  }, [QPQuantity])

  useEffect(() => {
    callBackAfterLogin()
  }, [account?.firstName])

  useEffect(() => {
    setQPQuantity(quantity)
  }, [quantity])

  useEffect(() => {
    if (custom?.fields?.pickUpStoreId) {
      getAvailability()
    }
  }, [custom])

  return (
    <>
      <Box position="relative" marginBottom={0} padding={4}>
        {/* Render remove button if onRemove function exists */}
        {removeOptions?.type === 'icon' && onRemove && (
          <CloseButton
            aria-label={removeButtonLabel}
            size="3xl"
            isDisabled={isLoading}
            onClick={async () => {
              await onRemove()
              handleUtagTracking(QPQuantity, TealiumTrackingType.CART_REMOVE)
            }}
            position="absolute"
            top={0}
            right={2}
          />
        )}

        <Flex gap={4}>
          {/* Product image display */}
          <Flex
            aspectRatio={3 / 4}
            width={imageSize.width}
            height={imageSize.height}
            opacity={isNotEnoughInStock ? '0.6' : undefined}
          >
            <Link href={_url} aria-label={name}>
              {imageSrc ? (
                <Image src={imageSrc} alt={imageAlt} fit="contain" width="full" height="full" />
              ) : (
                <Flex justify="center" align="center" width="full" height="full" bgColor="brand.muted">
                  <IoImagesOutline size="40px" color={brandSecondary} />
                </Flex>
              )}
            </Link>
          </Flex>
          <Flex flexDirection="column" width="100%">
            {/* Product details section */}
            <Flex {...contentColumn} grow={1}>
              <Flex direction="column" flex={1}>
                {/* Display brand and product name */}
                <Text tabIndex={0} fontSize="12px" fontWeight="400">
                  {brand}
                </Text>
                <Link
                  href={_url}
                  aria-label={name}
                  textStyle="body-100"
                  _hover={{ textDecoration: 'underline', color: 'black' }}
                  fontSize="14px"
                  fontWeight={600}
                >
                  {name}
                </Link>

                {/* Additional details and attributes */}
                <Box {...detailsOptions?.props} textStyle="body-50" style={{ wordWrap: 'break-word' }}>
                  <Text tabIndex={0} color="text.muted">
                    {productItemNumber ? `Item #: ${productItemNumber}, ` : ``} {parsedProductAttributes}
                  </Text>

                  {name != 'Gift Card' && name != 'ST JUDE DONATION' ? (
                    <LoyaltyPoints
                      pricedata={isDiscounted ? formatPrice(discountedPrice) : formatPrice(price)}
                      textAlign={'left'}
                    ></LoyaltyPoints>
                  ) : (
                    ''
                  )}

                  {isPromoExcluded && (!hasEdsCode || (hasEdsCode && QPQuantity > 1)) && (
                    <Box
                      role="textbox"
                      aria-label={formatMessage({ id: 'pdp.promotionexclution.message' })}
                      tabIndex={0}
                      textStyle={'body-50'}
                      color={'text-muted'}
                      dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'pdp.promotionexclution.message' }) }}
                      marginBottom={5}
                    />
                  )}

                  <CustomCharges quantity={quantity} />

                  {/* Display item price details */}
                  {!isMobile && itemPriceOptions.type === 'text' && <RenderPriceComponent />}
                  {isMobile && (
                    <Box width="100%" marginTop="10px">
                      <RenderPriceComponent />
                    </Box>
                  )}

                  {/* quantityOptions.type === 'text' is for cart drawer */}
                  {quantityOptions.type === 'text' && !isDonation && (
                    <Box {...quantityOptions?.props} marginBottom="10px">
                      <ConfiguredQuantityPicker />
                    </Box>
                  )}
                </Box>
              </Flex>

              {/* Display quantity picker in a stack for column layout */}
              {quantityOptions.type === 'column' && !isDonation && (
                <Stack {...quantityOptions?.props} flex="1">
                  {quantityOptions.label && (
                    <Text textStyle="heading-mobile-75" color={'text.muted'}>
                      {labels.quantity}
                    </Text>
                  )}
                  <ConfiguredQuantityPicker />
                </Stack>
              )}

              {itemPriceOptions.type === 'column' && (
                <Stack textAlign={'end'} marginRight="0" flex={0.7}>
                  {itemPriceOptions.label && (
                    <Text color={'text-muted'} textStyle={'heading-mobile-75'}>
                      {labels.itemPrice}
                    </Text>
                  )}

                  <Flex gap="5px" justifyContent="flex-end">
                    <Text textStyle={isDiscounted ? 'figma-strikethrough-75' : 'body-75'}>
                      {isDiscounted ? basePrice : price}
                    </Text>
                    {isDiscounted && (
                      <Text textStyle={'body-75'} color="danger.600">
                        {isDiscounted ? discountedPrice : totalPrice}
                      </Text>
                    )}
                  </Flex>

                  {promoDiscounts?.discountAmount > 0 && (
                    <Text tabIndex={0} fontSize={'12px'} textStyle={'body-50'} color={'danger.600'} fontWeight={400}>
                      {formatMessage({
                        id: 'product.price.sale.youSaved',
                        values: {
                          amount: CurrencyHelpersWithoutBorderConversion.formatForCurrency(
                            promoDiscounts.discountAmount,
                            currency || 'USD',
                          ),
                        },
                      })}
                    </Text>
                  )}

                  <ShowIfPromoCodeAppliedToLineItem discounts={discounts} appliedDiscountCode={appliedDiscountCode} />
                </Stack>
              )}
            </Flex>

            <Flex
              w="100%"
              justifyContent={!isMobile ? 'space-between' : ''}
              flexDirection={isMobile || !isCartPage ? 'column' : 'row'}
              position="relative"
            >
              <Box>
                {editable && isBOPISProduct && (
                  <OptionsGetProduct
                    lineItemId={lineItemId}
                    name={name}
                    sku={variant?.sku}
                    quantity={quantity}
                    orderByTime={optionsGetProduct?.orderByTime}
                    productPickUpStore={custom?.fields?.pickUpStoreName}
                    showGetProductOptions={showGetProductOptions}
                    isAvailableToday={isAvailable}
                  />
                )}
              </Box>
              <Box>
                {/* Render remove button */}
                {editable && onRemove && (
                  <Text
                    as="button"
                    textStyle="link-50"
                    onClick={
                      isLoading
                        ? undefined
                        : async () => {
                            await onRemove()
                            handleUtagTracking(QPQuantity, TealiumTrackingType.CART_REMOVE)
                          }
                    }
                    cursor={isLoading ? 'initial' : 'pointer'}
                    aria-label={removeButtonLabel}
                    color={isLoading ? 'text-muted' : undefined}
                    position={!isMobile && isCartPage ? 'absolute' : 'relative'}
                    bottom="0"
                    right="0"
                    marginBottom={!isCartPage ? '10px' : '0'}
                  >
                    {labels.remove}
                  </Text>
                )}
              </Box>
            </Flex>

            {isDropShipProduct && !isMobile && !usedInBagDrawer && (
              <Box marginTop="8px" width="100%">
                <SkeletonText isLoaded={true}>
                  <DropShipProductMessage />
                </SkeletonText>
              </Box>
            )}
          </Flex>
        </Flex>

        <Flex marginTop={isCartPage ? '10px' : '0'}>{alertMessage}</Flex>
        <AtgLoginPage isOpen={isLoginModelOpen} onClose={closeLoginModal} toggleForgotPassword={toggleForgotPassword} />
        <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={toggleForgotPassword} />
      </Box>

      {isDropShipProduct && (isMobile || usedInBagDrawer) && (
        <Box padding="0" width="100%">
          <SkeletonText isLoaded={true}>
            <DropShipProductMessage />
          </SkeletonText>
        </Box>
      )}
    </>
  )
}
